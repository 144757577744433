






































import { defineComponent, ref } from '@vue/composition-api'
import { endpoints, showError } from 'utils'
import { api } from 'plugins'

const FixImport = defineComponent({
  props: {
    errorImport: {
      type: Object,
      required: true
    },
    isNext: {
      type: Boolean,
      required: false
    },
    isPrev: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { root, emit }) {
    const openListImport = () => {
      emit('on-list')
    }

    const close = () => {
      emit('on-close', false)
    }

    const next = () => {
      emit('on-next')
    }

    const prev = () => {
      emit('on-prev')
    }

    const updateSalesImportDetail = async (id: number) => {
      try {
        const oldVersionEdited = props.errorImport.edited
        await api.put(
          `${
            endpoints.CUSTOMER_SALES_IMPORT
          }update_customer_sales_import_detail/${id}?edited=${!oldVersionEdited}`
        )
        emit('on-update', id, !oldVersionEdited)
      } catch (e) {
        showError(e, root.$toast, root.$t('sales.import_from_csv_detail.update_error') as string)
      }
    }

    return {
      openListImport,
      close,
      next,
      prev,
      updateSalesImportDetail
    }
  }
})
export default FixImport
