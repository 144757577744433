



















































import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  provide,
  getCurrentInstance
} from '@vue/composition-api'
import {
  AddButton,
  ChooseNumber,
  LoaderComponent,
  StepButton,
  ConfirmDelete,
  BottomSheetContainer
} from 'components'
import { getOneApi, toCamelCase, endpoints, getData, toSnakeCase, patchData } from 'utils'
import SalesResult from './SalesResult.vue'
import FeeTable from './FeeTable.vue'
import SummaryTable from './SummaryTable.vue'
import AddFeeDialog from './AddFeeDialog.vue'
import ConfirmSale from './ConfirmSale.vue'
// eslint-disable-next-line import/named
import { initSummary } from './index'

const SalesCustomer = defineComponent({
  components: {
    AddFeeDialog,
    FeeTable,
    StepButton,
    AddButton,
    ChooseNumber,
    LoaderComponent,
    SalesResult,
    SummaryTable,
    ConfirmDelete,
    BottomSheetContainer,
    ConfirmSale
  },
  setup(props, { root }) {
    const instance: any = getCurrentInstance()?.proxy
    provide('getPage', instance)
    const { $route, $refs } = instance
    const { salId } = $route.params
    const salDate = $route.query.auction_date
    const isConfirmMainSale = ref(false)
    const arItems = ref([])
    const arUnits = ref([])
    const loading = ref(true)
    const showConfirmDelete = ref(false)
    const isShowConfirmSale = ref(false)
    const taxRate = ref()
    const datesInSummary = ref([])
    const customer = ref()
    const sale = ref({
      sale: null,
      saleDetails: [],
      feesWithTax: [],
      fees: [],
      summary: initSummary
    })
    const arrSaleDetails = ref([])

    const onOpenFeeDialog = (isWithTax: boolean) => {
      $refs.fee.addFeeArgument.show = !isConfirmMainSale.value
      $refs.fee.addFeeArgument.isAdd = true
      $refs.fee.addFeeArgument.isTaxExcluded = !isWithTax
    }
    const toConfirmSale = async () => {
      if (datesInSummary.value.length > 1) isShowConfirmSale.value = true
      else {
        // eslint-disable-next-line no-use-before-define
        await confirmSaleResult(datesInSummary.value[0])
        isShowConfirmSale.value = false
      }
    }
    const bottomSheetSale = reactive({
      show: false,
      buttons: [
        {
          text: root.$t('sale.add_fee'),
          disable: isConfirmMainSale.value,
          icon: 'mdi-plus',
          event: () => {
            onOpenFeeDialog(false)
            bottomSheetSale.show = false
          }
        },
        {
          text: root.$t('sale.add_fee_with_tax'),
          disable: isConfirmMainSale.value,
          icon: 'mdi-plus',
          event: () => {
            onOpenFeeDialog(true)
            bottomSheetSale.show = false
          }
        },
        {
          text: root.$t('sale.set_confirm'),
          event: () => {
            toConfirmSale()
            bottomSheetSale.show = false
          }
        },
        {
          text: root.$t('common.cancel'),
          event: () => {
            bottomSheetSale.show = false
          }
        }
      ]
    })

    const updateBottomSheetSale = () => {
      // eslint-disable-next-line no-return-assign,no-param-reassign
      bottomSheetSale.buttons.forEach((item) => (item.disable = isConfirmMainSale.value))
      // cancel and confirm
      bottomSheetSale.buttons[bottomSheetSale.buttons.length - 1].disable = false
      bottomSheetSale.buttons[bottomSheetSale.buttons.length - 2].disable = false
    }

    const getDateSummary = async () => {
      const data = await getOneApi(`${endpoints.SALE}summary/?sal_id=${salId}`)
      datesInSummary.value = data.dates ? toCamelCase(data.dates) : []

      const confirmDates = datesInSummary.value
        .filter((e: any) => e.isConfirmed)
        .map((e: any) => e.auctionDate)

      isConfirmMainSale.value = confirmDates.includes(salDate)
      updateBottomSheetSale()
    }

    const getDataMaster = async () => {
      const data = await getData(['arItem', 'arUnit', 'vat'], {}, { date: salDate })
      arItems.value = data.arItems ? toCamelCase(data.arItems) : []
      arUnits.value = data.arUnits ? toCamelCase(data.arUnits) : []
      taxRate.value = data.vat ? toCamelCase(data.vat) : {}
    }

    const getDataSale = async () => {
      const data = await getOneApi(`${endpoints.SALE}?sale_id=${salId}`)
      sale.value = toCamelCase(data)
    }

    const getCustomer = async () => {
      const data = await getOneApi(`${endpoints.SALE}customer?auction_date=${salDate}`)
      customer.value = data
        ? toCamelCase(data).filter((cus: { salId: number }) => cus.salId === Number(salId))[0]
        : {}
    }

    const reload = async () => {
      await getDataSale()
      await getDataMaster()
      await getDateSummary()
      await getCustomer()
    }

    onMounted(async () => {
      loading.value = true
      await getDataSale()
      await getDataMaster()
      await getDateSummary()
      await getCustomer()
      loading.value = false
    })

    const confirmSaleResult = async (summary: any) => {
      const body = summary ? toSnakeCase(summary) : {}
      body.sale_id = body.sal
      body.is_confirmed = !body.is_confirmed
      const [data, success] = await patchData(`${endpoints.SALE}summary`, body)
      if (!success) return
      await getDataSale()
      await getDateSummary()
      await getCustomer()
      isShowConfirmSale.value = true
    }
    return {
      arrSaleDetails,
      sale,
      loading,
      arUnits,
      arItems,
      taxRate,
      showConfirmDelete,
      isShowConfirmSale,
      datesInSummary,
      confirmSaleResult,
      bottomSheetSale,
      getDataSale,
      isConfirmMainSale,
      getDateSummary,
      toConfirmSale,
      customer,
      reload,
      salDate
    }
  }
})

export default SalesCustomer
