













































































import { defineComponent } from '@vue/composition-api'
import { StepButton } from 'components'

const SummaryTable = defineComponent({
  components: {
    StepButton
  },
  props: {
    summary: {
      type: Object,
      required: true
    }
  }
})
export default SummaryTable
