










































import { defineComponent } from '@vue/composition-api'

const searchResultsPanel = defineComponent({
  props: {
    data: {
      type: Object,
      required: false
    },
    headers: {
      type: Array,
      required: false
    }
  }
})
export default searchResultsPanel
