























































































import { defineComponent, ref, watch, inject } from '@vue/composition-api'
import { DateDialog, ConfirmDelete } from 'components'
import moment from 'moment'
import { endpoints, toSnakeCase, postData } from 'utils'

const BreakDownDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    saleDetail: {
      type: Object,
      required: true
    },
    sale: {
      type: Object,
      required: true
    }
  },
  components: {
    DateDialog,
    ConfirmDelete
  },
  setup(props, { emit }) {
    const breakdowns = ref([] as any)
    const delBreakDown = ref<Array<number>>([])
    const rootPage: any = inject('getPage')
    const showDelete = ref(false)
    const confirmDeleteEvent = ref<any>({ 'close-dialog': () => null })

    const addSalesDateBreakDown = () => {
      breakdowns.value.push({
        id: null,
        auctionDate: moment(new Date()).format('YYYY-MM-DD'),
        saleStem: 0,
        saleAmount: 0,
        remaining: null,
        saldId: props.saleDetail.id
      })
    }

    const calculateRemain = () => {
      let remain = 0
      breakdowns.value = breakdowns.value.map((breakDown: any) => {
        remain += breakDown.saleStem
        return { ...breakDown, remaining: props.saleDetail.stem - remain }
      })
    }

    const onInput = (value: any, field: string, index: number) => {
      breakdowns.value[index][field] = ['saleAmount', 'saleStem'].includes(field)
        ? Number(value)
        : value
      calculateRemain()
    }

    const onSave = async () => {
      const body = {
        breakDowns: breakdowns.value.filter((e: any) => !e.disabled),
        deleteBreakDown: [...delBreakDown.value],
        saldId: props.saleDetail.id
      }
      const [data, success] = await postData(`${endpoints.SALE}break_down`, toSnakeCase(body))
      if (!success) return
      await rootPage.getDataSale()
      await rootPage.getDateSummary()
      emit('on-close')
    }

    const onDelete = (id: number | null, index: number) => {
      if (id) showDelete.value = true
      else breakdowns.value.splice(index, 1)
      confirmDeleteEvent.value['close-dialog'] = (mode: string) => {
        if (mode === 'delete' && id && !delBreakDown.value.includes(id)) {
          delBreakDown.value.push(id)
          breakdowns.value.splice(index, 1)
        }
        showDelete.value = false
      }
      // if (id && !delBreakDown.value.includes(id)) delBreakDown.value.push(id)
    }

    const init = () => {
      if (!props.value) return
      delBreakDown.value = []
      const confirmDates = rootPage.datesInSummary
        .filter((e: any) => e.isConfirmed)
        .map((e: any) => e.auctionDate)
      if (props.saleDetail.breakDowns.length === 0) {
        if (
          !props.saleDetail.saleStem &&
          !props.saleDetail.saleAmount &&
          confirmDates.includes(props.sale.auctionDate)
        )
          breakdowns.value = []
        else
          breakdowns.value = [
            {
              id: null,
              auctionDate: props.sale.auctionDate,
              saleStem: props.saleDetail.saleStem,
              saleAmount: props.saleDetail.saleAmount,
              remaining: 0,
              saldId: props.saleDetail.id,
              disabled: confirmDates.includes(props.sale.auctionDate)
            }
          ]
      } else
        breakdowns.value = [...props.saleDetail.breakDowns].map((brd: any) => {
          return { ...brd, disabled: confirmDates.includes(brd.auctionDate) }
        })
    }

    watch(
      () => props.value,
      () => init()
    )
    return {
      addSalesDateBreakDown,
      onInput,
      breakdowns,
      onSave,
      onDelete,
      showDelete,
      confirmDeleteEvent
    }
  }
})

export default BreakDownDialog
