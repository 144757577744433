










































































































import { computed, defineComponent, ref, inject, reactive } from '@vue/composition-api'
import { ExpandableTable, ConfirmDelete, BottomSheetContainer } from 'components'
import moment from 'moment'
import { numberToString, compactText, endpoints, patchData } from 'utils'
import ArDetailDialog from './ArDetailDialog.vue'
import RemarkDialog from './RemarkDialog.vue'
import { searchArHeaders, searchArHeadersExpand } from './index'

const arTable = defineComponent({
  components: {
    ExpandableTable,
    ArDetailDialog,
    ConfirmDelete,
    BottomSheetContainer,
    RemarkDialog
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    arItems: {
      type: Array,
      required: false
    },
    arUnits: {
      type: Array,
      required: false
    },
    isSelectedAll: {
      type: Boolean,
      required: false,
      default: false
    },
    itemKey: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { root }) {
    const headers = ref(searchArHeaders)
    const headersExpand = ref(searchArHeadersExpand)
    const rootPage: any = inject('getPage')
    const arDetail = ref<any>({ ...{} })
    const showArDetailDialog = ref(false)
    const showConfirmDelete = ref(false)
    const isAdd = ref(false)
    const remarkDialog = reactive({ value: false, remark: '' })

    const addOrEditArDetail = (isAddAr: boolean) => {
      isAdd.value = isAddAr
      showArDetailDialog.value = true
    }

    const bottomSheet = reactive({
      show: false,
      buttons: [] as any[]
    })

    const arDetailAction = [
      {
        text: root.$t('common.add'),
        iconColor: 'green',
        icon: 'mdi-plus',
        event: () => addOrEditArDetail(true)
      },
      {
        text: root.$t('common.edit'),
        icon: 'mdi-pencil',
        event: () => addOrEditArDetail(false)
      },
      {
        text: root.$t('common.delete'),
        icon: 'mdi-delete-empty',
        iconColor: 'red',
        event: () => {
          showConfirmDelete.value = true
          bottomSheet.show = false
        }
      },
      {
        text: root.$t('common.cancel'),
        event: () => {
          bottomSheet.show = false
        }
      }
    ]

    const arRemarkAction = [
      {
        text: root.$t('common.edit'),
        icon: 'mdi-pencil',
        event: () => {
          remarkDialog.remark = arDetail.value.ar.remark
          remarkDialog.value = true
        }
      },
      {
        text: root.$t('common.cancel'),
        event: () => {
          bottomSheet.show = false
        }
      }
    ]

    const openBottomButton = (detail: any, isRemark = false) => {
      bottomSheet.buttons = isRemark ? [...arRemarkAction] : [...arDetailAction]
      bottomSheet.show = true
      arDetail.value = detail
    }

    const confirmDeleteArDetail = async (action: string): Promise<void> => {
      if (action === 'delete') {
        const param = {
          deleted_details: [arDetail.value.arDetail.id],
          details: [],
          is_update_remark: false,
          remark: null
        }
        await patchData(`${endpoints.AR}${arDetail.value.ar.id}`, param)
        await rootPage.getDataAr()
      }
      showConfirmDelete.value = false
    }
    const onClose = () => {
      showArDetailDialog.value = false
      bottomSheet.show = false
    }

    const showItems = computed(() => {
      return props.items.map((item: any) => {
        return {
          ...item,
          auctionDateToClosed: moment(item.auctionDateToClosed).format('MM月DD日(ddd)'),
          auctionDateTo: moment(item.auctionDateTo).format('MM月DD日(ddd)'),
          expandItems: item.ars,
          expandItem: item.ars.arDetails
        }
      })
    })

    const saveRemark = async (input: string | null) => {
      remarkDialog.value = false
      bottomSheet.show = false
      const param = {
        deleted_details: [],
        details: [],
        is_update_remark: true,
        remark: input
      }
      await patchData(`${endpoints.AR}${arDetail.value.ar.id}`, param)
      await rootPage.getDataAr()
    }

    const getDataAr = (data: any[]) => {
      let result: any[] = []
      data.forEach((ar) => {
        const arDetails = ar.arDetails.map((detail: any, index: any) => {
          return {
            ar,
            arDetail: detail,
            arDetailIndex: index,
            length: ar.arDetails.length,
            isTotal: false
          }
        })
        result = [
          ...result,
          { isTitle: true, ar, arDetail: {} },
          ...arDetails,
          { isTotal: true, ar, arDetail: {} },
          { isRemark: true, ar, arDetail: {} }
        ]
      })
      return result
    }

    return {
      headers,
      headersExpand,
      showItems,
      numberToString,
      getDataAr,
      compactText,
      openBottomButton,
      arDetail,
      showArDetailDialog,
      isAdd,
      showConfirmDelete,
      confirmDeleteArDetail,
      bottomSheet,
      onClose,
      remarkDialog,
      saveRemark
    }
  }
})
export default arTable
