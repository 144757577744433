import { toSnakeCase } from 'utils'

export interface Summary {
  id: number | null
  amount: number | null
  box: number | null
  deductionAmount: string | null
  feeAmount: number | null
  feeAmountRate: number | null
  feeAmountRate2: number | null
  feeAmountWithTax: number | null
  productAmount: number | null
  rate2BaseAmount: number | null
  rateBaseAmount: number | null
  sal: number | null
  saleAmount: number | null
  saleStem: number | null
  stem: number | null
  taxAmountRate: number | null
  taxAmountRate2: number | null
}

export interface Sale {
  saleDetails: Array<any>
  feesWithTax: []
  fees: []
  summary: Summary
}

export const initSummary: Summary = {
  id: null,
  amount: null,
  box: null,
  deductionAmount: null,
  feeAmount: null,
  feeAmountRate: null,
  feeAmountRate2: null,
  feeAmountWithTax: null,
  productAmount: null,
  rate2BaseAmount: null,
  rateBaseAmount: null,
  sal: null,
  saleAmount: null,
  saleStem: null,
  stem: null,
  taxAmountRate: null,
  taxAmountRate2: null
}
export const initSaveFee = {
  id: null,
  fee: null,
  fee_with_tax: null,
  edit_table: null,
  sale_detail: null
}
export const detailFee = {
  arItem: null,
  box: null,
  price: null,
  unit: null,
  taxRate: null,
  feeAmount: null
}
export const detailFeeWithTax = {
  arItem: null,
  box: null,
  price: null,
  unit: null,
  feeAmount: null
}

export const toBodySaveDetail = (sale: any, sald: any) => {
  return toSnakeCase({
    id: sale.id,
    fee: null,
    feeWithTax: null,
    saleDetail: {
      id: sald.id,
      saleStem: sald.saleStem,
      saleAmount: sald.saleAmount,
      stem: sald.stem,
      averagePrice:
        sald.saleAmount / sald.saleStem ? Number(sald.saleAmount / sald.saleStem).toFixed(0) : 0,
      taxRate: 'rate',
      taxRateValue: 10.0
    },
    editTable: ['sale_detail']
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toFeeBody = (fee: any, salId: any, isTaxExcluded: boolean, delId = null) => {
  const feeBody: any = {
    id: fee.id,
    ar_item_id: fee.arItem.id,
    ar_unit_id: fee.arUnit.id,
    box: fee.box,
    amount: fee.feeAmount,
    tax_rate: fee.taxRate,
    tax_rate_type: fee.arItem.taxType,
    price: fee.price,
    delete_id: delId
  }
  return {
    id: salId,
    fee: isTaxExcluded ? { ...feeBody } : null,
    fee_with_tax: !isTaxExcluded ? { ...feeBody } : null,
    sale_detail: null,
    edit_table: isTaxExcluded ? ['fee'] : ['fee_with_tax']
  }
}
