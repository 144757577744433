






import { defineComponent } from '@vue/composition-api'
import { MasterAuctionDate, MasterNoSaleDate } from 'components'

const AuctionDate = defineComponent({
  components: {
    MasterAuctionDate,
    MasterNoSaleDate
  }
})
export default AuctionDate
