import { i18n } from 'plugins'
import router from '../../router'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const replaceUrl = (query: any, params: any = {}) => {
  // type query and params is object
  router
    .replace({
      query,
      params
    })
    .catch((e) => {
      return e
    })
}
const searchResultsHeaders = [
  {
    text: '',
    width: '10%',
    height: '23px',
    align: 'end'
  },
  {
    text: i18n.t('accounts_receivable.total_ar_number'),
    sortable: false,
    value: 'total_ar_number',
    width: '25%',
    height: '23px',
    align: 'start'
  },
  {
    text: i18n.t('accounts_receivable.total_ar_details'),
    sortable: false,
    value: 'total_ar_details',
    width: '20%',
    height: '23px',
    align: 'start'
  },
  {
    text: i18n.t('accounts_receivable.date'),
    sortable: false,
    value: 'date',
    width: '25%',
    height: '23px',
    align: 'start'
  },
  {
    text: i18n.t('accounts_receivable.total_amount'),
    sortable: false,
    value: 'total_amount',
    width: '25%',
    height: '23px',
    align: 'start'
  }
]
const searchArHeaders = [
  {
    sortable: false,
    value: 'checkbox',
    text: 'checkbox',
    width: 10,
    colspan: 1
  },
  {
    text: i18n.t('accounts_receivable.customer'),
    sortable: false,
    value: 'customerName',
    width: 35,
    colspan: 1
  },
  {
    text: i18n.t('accounts_receivable.date'),
    sortable: false,
    value: 'auction_date_from_to',
    align: 'end',
    width: 10,
    colspan: 2
  },
  {
    text: i18n.t('accounts_receivable.amount'),
    sortable: false,
    value: 'amount',
    align: 'end',
    width: 35,
    colspan: 1
  },
  {
    sortable: false,
    value: 'data-table-expand',
    width: 10,
    colspan: 1
  }
]
const searchArHeadersExpand = [
  {
    sortable: false,
    value: 'checkbox',
    text: 'checkbox',
    width: '5%',
    colspan: 1
  },
  {
    text: i18n.t('accounts_receivable.ar_item'),
    sortable: false,
    value: 'ar_item',
    align: 'start',
    width: '25%',
    colspan: 1
  },
  {
    text: i18n.t('accounts_receivable.quantity'),
    sortable: false,
    value: 'quantity',
    align: 'start',
    width: '15%',
    colspan: 1
  },
  {
    text: i18n.t('accounts_receivable.price'),
    sortable: false,
    value: 'price',
    align: 'end',
    width: '25%',
    colspan: 1
  },
  {
    text: i18n.t('accounts_receivable.amount'),
    sortable: false,
    value: 'amount',
    align: 'end',
    width: '25%',
    colspan: 1
  },
  {
    sortable: false,
    value: 'data-table-expand',
    align: 'end',
    width: '5%',
    colspan: 1
  }
]
const arDialogHeaders = [
  {
    text: '',
    sortable: false,
    value: 'ar_item',
    width: 12
  },
  {
    text: i18n.t('accounts_receivable.quantity'),
    sortable: false,
    value: 'quantity',
    align: 'end',
    width: 8
  },
  {
    text: i18n.t('accounts_receivable.price'),
    sortable: false,
    value: 'price',
    align: 'end',
    width: 8
  },
  {
    text: i18n.t('accounts_receivable.amount'),
    sortable: false,
    value: 'amount',
    align: 'end',
    width: 10
  }
]

const AR_DETAIL_ACTIONS = [
  {
    id: 1,
    text: 'common.add',
    icon: 'mdi-plus',
    action: 'add',
    disabled: false,
    color: 'green'
  },
  {
    id: 2,
    text: 'common.edit',
    icon: 'mdi-pencil',
    action: 'edit',
    disabled: false,
    color: 'gray'
  },
  {
    id: 3,
    text: 'common.delete',
    icon: 'mdi-delete-empty',
    action: 'delete',
    disabled: false,
    color: 'red'
  }
]

const AR_REMARK_ACTIONS = [
  {
    id: 1,
    text: 'common.edit',
    icon: 'mdi-pencil',
    action: 'edit-remark',
    disabled: false,
    color: 'gray'
  }
]

const ArClosingStatusList = [
  {
    value: null,
    name: i18n.t('accounts_receivable.null')
  },
  {
    value: 1,
    name: i18n.t('accounts_receivable.not_closing_only')
  },
  {
    value: 2,
    name: i18n.t('accounts_receivable.closed_only')
  }
]

const BillingClosingStatusList = [
  {
    value: null,
    name: i18n.t('accounts_receivable.null')
  },
  {
    value: 1,
    name: i18n.t('accounts_receivable.not_billed_only')
  },
  {
    value: 2,
    name: i18n.t('accounts_receivable.billed_only')
  }
]
const initTotalSearched = {
  totalArNumber: 0,
  totalArDetails: 0,
  totalAmountClosedAr: 0,
  dateFrom: null,
  dateTo: null,
  date: null,
  totalAmount: 0,
  alreadyClosedArDateFrom: null,
  alreadyClosedArDateTo: null
}

const bottomSheetListButton = [
  {
    id: 1,
    text: 'add' as string,
    classes: [],
    icon_color: 'green',
    icon: 'mdi-plus',
    action: 'on-add',
    disabled: false
  },
  {
    id: 2,
    text: 'edit' as string,
    classes: [],
    icon: 'mdi-pencil',
    action: 'on-edit',
    disabled: false
  },
  {
    id: 3,
    text: 'delete' as string,
    classes: [],
    icon_color: 'red',
    icon: 'mdi-delete-empty',
    action: 'on-delete',
    disabled: false
  },
  {
    id: 4,
    text: i18n.t('common.cancel') as string,
    classes: ['red--text'],
    icon: '',
    action: 'on-close',
    disabled: false
  }
]

export {
  searchResultsHeaders,
  arDialogHeaders,
  AR_DETAIL_ACTIONS,
  AR_REMARK_ACTIONS,
  initTotalSearched,
  searchArHeaders,
  searchArHeadersExpand,
  ArClosingStatusList,
  BillingClosingStatusList,
  bottomSheetListButton
}
