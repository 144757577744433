






























import { defineComponent } from '@vue/composition-api'
import { ChooseNumber, StepButton } from 'components'

const ConfirmSale = defineComponent({
  props: {
    isShowConfirmSale: {
      type: Boolean,
      default: false
    },
    datesInSummary: {
      type: Array,
      required: true
    }
  },
  components: {
    StepButton,
    ChooseNumber
  },
  setup(props, { emit }) {
    const confirmSale = (sum: any) => {
      emit('confirm-sale', sum)
    }
    return {
      confirmSale
    }
  }
})

export default ConfirmSale
