<template lang="pug">
  div
    v-expansion-panels(:value="0")
      v-expansion-panel
        v-expansion-panel-header.color-base.white--text
          span.large-text {{ $t('sales.sale_result') }}
          template(v-slot:actions)
            v-icon.white--text mdi-chevron-down
        v-expansion-panel-content
          div(v-for="saleDetail in sale.saleDetails")
            v-row.ma-0.pa-0.color
              v-col
                span {{ saleDetail.productName }}
              v-btn.mt-2(
                @click.stop="curDetail = saleDetail, bottomSheet.show = true"
                icon
                small
              )
                v-icon mdi-dots-vertical
            v-list-item.ma-0.pl-0.pr-2(
              style="width: 100%; text-align: -webkit-right"
            )
              v-row.ma-0
                v-col.pa-0.ma-0
                v-col.pa-0.ma-0
                  step-button(
                    :step-name="'total_boxes'"
                    :showValueZero="saleDetail.box === 0"
                    :value="saleDetail.box"
                    medium
                  )
                v-col.pa-0.ma-0
                  step-button(
                    :step-name="'total_stems'"
                    :showValueZero="saleDetail.stem === 0"
                    :value="saleDetail.stem"
                    medium
                  )
                v-col.pa-0.ma-0
                  step-button(
                    :step-name="'sale_stem'"
                    :value="saleDetail.saleStem"
                    :clickable= "true"
                    :background-color="saleDetail.breakDowns.length > 0 ? '#fcffe0' : '#FFFFFF'"
                    :showValueZero="saleDetail.saleStem === 0 || saleDetail.saleStem === null"
                    @on-click="curDetail = saleDetail, saleDetail.breakDowns.length > 0 ? isOpenBreakDownDialog = true : openNumberDialog(saleDetail, 'sale_stem')"
                    medium
                  )
                  //span {{ assign.salesResult.version }}
                v-col.pa-0.ma-0
                  step-button(
                    :step-name="'sale_amount'"
                    :value="saleDetail.saleAmount"
                    :clickable= "true"
                    :background-color="saleDetail.breakDowns.length > 0 ? '#fcffe0' : '#FFFFFF'"
                    :showValueZero="saleDetail.saleAmount === 0 || saleDetail.saleAmount === null"
                    @on-click="curDetail = saleDetail, saleDetail.breakDowns.length > 0 ? isOpenBreakDownDialog = true : openNumberDialog(saleDetail, 'sale_amount')"
                    medium
                  )

                v-col.pa-0.ma-0
                  step-button(
                    :step-name="'average_price'"
                    :showValueZero="saleDetail.saleAmount/ saleDetail.saleStem === 0"
                    :value="saleDetail.saleAmount / saleDetail.saleStem ? Number(Number(saleDetail.saleAmount / saleDetail.saleStem).toFixed(0)) : 0"
                    medium
                  )
          v-row.ma-0.pa-0
            v-col
              span {{ $t('sales.total') }}
          v-list-item.ma-0.pl-0.pr-2.color(
            style="width: 100%; text-align: -webkit-right"
          )
            v-row.ma-0
              v-col.pa-0.ma-0
              v-col.pa-0.ma-0
              v-col.pa-0.ma-0
              v-col.pa-0.ma-0
                step-button(
                  :step-name="'sale_amount'"
                  :showValueZero="!!sale.summary.saleAmount"
                  :value="sale.summary.saleAmount"
                  medium
                )
              v-col.pa-0.ma-0
                step-button(
                  :step-name="'10%'"
                  :showValueZero="!!sale.summary.productAmount"
                  :value="sale.summary.productAmount"
                  medium
                )
              v-col.pa-0.ma-0.mb-2
                step-button(
                  :step-name="'8%'"
                  :showValueZero="!!sale.summary.rate2BaseAmount"
                  :value="sale.summary.rate2BaseAmount"
                  medium
                )

      break-down-dialog(
        :value="isOpenBreakDownDialog"
        :sale-detail="curDetail"
        :sale="sale.sale"
        @on-close="isOpenBreakDownDialog = false"
      )

      choose-number(
        v-bind="numberDialog"
        @input="numberDialog.value = $event"
        @on-change="editSaleDetail"
      )

      bottom-sheet-container(v-bind="bottomSheet")
</template>
<script lang="js">
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { StepButton, ChooseNumber, BottomSheetContainer } from 'components'
import moment from 'moment'
import { putData, endpoints, toCamelCase } from 'utils'
import { toBodySaveDetail } from './index'
import BreakDownDialog from './BreakDownDialog.vue'

const SalesResult = defineComponent({
  props: {
    sale: {
      type: Object,
      required: true
    },
    isConfirmMainSale: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StepButton,
    ChooseNumber,
    BreakDownDialog,
    BottomSheetContainer
  },
  setup(props, { root, emit }) {
    const { cst, auctionDate } = props.sale.sale
    const { $toast } = root
    const currentProperty = ref('')
    const listProperty = ['sale_stem', 'sale_amount']
    const numberDialog = reactive({
      value: false, inputValue: 0, title: '', title2: '', titleBottom: '', titleBottom2: '', label: ''
    })
    const isOpenBreakDownDialog = ref(false)
    const bottomSheet = reactive({
      show: false,
      buttons: [
        {
          text: root.$t('sales.add_more_sales.title'),
          event: () => {
            isOpenBreakDownDialog.value = true
            bottomSheet.show = false
          }
        },
        {
          text: root.$t('common.cancel'),
          event: () => {
            bottomSheet.show = false
          }
        }
      ]
    })

    const curDetail = ref({})

    const openNumberDialog = (sald, stepName) => {
      curDetail.value = sald
      currentProperty.value = stepName
      numberDialog.value = !props.isConfirmMainSale
      numberDialog.title = sald.productName
      numberDialog.title2 = root.$t('sales.box_stem', { box: sald.box, stem: sald.stem, unit: sald.unit.name })
      numberDialog.titleBottom = `[${cst.code}]${cst.shortName || cst.name}`
      numberDialog.titleBottom2 = moment(auctionDate).format(root.$t('common.time_month_date').toString())
      numberDialog.inputValue = (stepName === listProperty[0] ? sald.saleStem : sald.saleAmount) || 0
      numberDialog.label = stepName
      if (!numberDialog.value) $toast.info('can not edit because this date has been comfimed')
    }

    const findNextSaleDetail = (mode) => {
      // add condition no breakdown
      const salds = props.sale.saleDetails.filter((sald) => sald.breakDowns.length === 0)
      // find index current assign
      const curIndex = salds.findIndex(
        (sald) => sald.id === curDetail.value.id
      )
      if (mode === 'next' && curIndex + 1 === salds.length) return false
      if (mode === 'prev' && curIndex + 1 === 0) return false

      return mode === 'next' ? salds[curIndex + 1] : salds[curIndex - 1]
    }

    const saveDetail = async () => {
      const body = toBodySaveDetail(props.sale.sale, curDetail.value)
      const [data, success] = await putData(`${endpoints.SALE}update`, body)
      if (success) emit('update-sale', toCamelCase(data))
      return !!data
    }

    const editSaleDetail = async (action, data) => {
      const pro = listProperty[0] === currentProperty.value ? listProperty[1] : listProperty[0]
      curDetail.value[currentProperty.value === listProperty[0] ? 'saleStem' : 'saleAmount'] = data
      if (action === 'next') {
        // just call api when change data
        if (numberDialog.inputValue !== data) {
          const success = await saveDetail(data)
          if (!success) return
        }
        // if current property is amount => next assign
        if (currentProperty.value === listProperty[1]) {
          const nextSald = findNextSaleDetail('next')
          if (nextSald) {
            curDetail.value = nextSald
            numberDialog.inputValue = curDetail.value.salesStems
          } else {
            // if this is last element => close dialog
            numberDialog.value = false
            return
          }
        } else numberDialog.inputValue = curDetail.value.salesAmount
      } else if (action === 'prev') {
        // just call api when change data
        if (numberDialog.inputValue !== data) {
          const success = await saveDetail(data)
          if (!success) return
        }
        // if current property is amount => next assign
        if (currentProperty.value === listProperty[0]) {
          const nextSald = findNextSaleDetail('prev')
          if (nextSald) {
            curDetail.value = nextSald
            numberDialog.inputValue = curDetail.value.salesAmount
          } else {
            // if this is last element => close dialog
            numberDialog.value = false
            return
          }
        } else numberDialog.inputValue = curDetail.value.salesStems
      }

      setTimeout(() => {
        openNumberDialog(
          curDetail.value,
          pro
        )
      }, 20)
    }

    return {
      openNumberDialog,
      numberDialog,
      editSaleDetail,
      curDetail,
      isOpenBreakDownDialog,
      bottomSheet
    }
  }
})

export default SalesResult
</script>
<style lang="sass" scoped>
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap
  padding: 0 0 0 !important
.color-base
  background-color: #343F4B
  max-height: 48px
.v-expansion-panel--active > .v-expansion-panel-header
  min-height: 48px
.v-expansion-panel-header
  border-radius: unset
  padding: 8px 8px
</style>
