







































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { readFile, endpoints, showError, toCamelCase, toSnakeCase } from 'utils'
import { api } from 'plugins'
import { SelectTab } from 'typings'
import moment from 'moment'
import ImportCSVDetailDialog from './ImportCSVDetailDialog.vue'

interface Record {
  boxes: number
  comment: string | null
  customerSalesImportId: number
  id: number
  price: number | null
  quantity: number
  size: string
  salesStems: number
  salesAmount: number
  productName: string
  status: number
}

const ImportCSVDialog = defineComponent({
  components: { ImportCSVDetailDialog },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    auctionDate: {
      type: String,
      required: false,
      default: moment(new Date()).format('YYYY-MM-DD').toString()
    },
    isOpenList: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const { $toast } = root
    const check = ref('pdf')
    const date = ref<string>()
    const auctionDateModal = ref(false)
    const items = ref<SelectTab[]>([])
    const customersImported = ref<any[]>([])
    const selectedCustomerImported = ref<any>(null)
    const isImportFromCSVDialog = ref(false)
    const fileInput = ref<any>()
    const selected = ref<number | null>(null)
    const listErrorImport = ref<Array<Record>>([])

    const close = () => {
      return emit('on-close', false)
    }

    const getData = async () => {
      try {
        const data = await Promise.all([
          api.get(`${endpoints.CUSTOMER_SALES_IMPORT}?auction_date=${date.value}`),
          api.get(`${endpoints.CUSTOMERS}`)
        ])
        const [{ data: customerSalesData }, { data: customerData }] = data
        items.value = toCamelCase(customerData)
          .filter((customer: any) => customer.allowImportCsv)
          .map((customer: any) => {
            return {
              name: `[${customer.code}] ${customer.shortName ? customer.shortName : customer.name}`,
              key: customer.id
            }
          })
        customersImported.value = toCamelCase(customerSalesData)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
      // only show customer allow import csv in select box
    }

    // open start fix and send error records to component Start/index
    const openStartFix = (errorRecords: Array<Record>) => {
      listErrorImport.value = errorRecords
      emit('on-fix', listErrorImport.value)
      close()
    }

    const importCsvAPI = async (body: any) => {
      try {
        await api.post(`${endpoints.CUSTOMER_SALES_IMPORT}import_file`, toSnakeCase(body))
        $toast.success(root.$t('sales_result.import_successful'))
        // close()
        fileInput.value = null
        selected.value = null
        await getData()
        emit('reload')
      } catch (e) {
        showError(e, $toast, root.$t('sales_result.import_failed') as string)
      }
    }

    const handleImport = async () => {
      if (!fileInput.value) {
        $toast.error(root.$t('sales_result.miss_file'))
        return
      }
      const payload = await readFile(fileInput.value)
      const body = {
        customer: selected.value,
        auctionDate: date.value,
        file: {
          name: fileInput.value.name,
          type: fileInput.value.type,
          size: fileInput.value.size,
          payload
        }
      }
      await importCsvAPI(body)
    }

    const goToDate = async () => {
      await getData()
    }

    watch(
      () => props.value,
      async () => {
        if (props.value) {
          date.value = props.auctionDate
          await getData()
        }
      }
    )

    watch(
      () => props.isOpenList === true,
      () => {
        isImportFromCSVDialog.value = props.isOpenList
      }
    )

    return {
      check,
      date,
      auctionDateModal,
      items,
      isImportFromCSVDialog,
      close,
      goToDate,
      handleImport,
      fileInput,
      customersImported,
      selected,
      selectedCustomerImported,
      openStartFix
    }
  }
})

export default ImportCSVDialog
