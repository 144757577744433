





































import { defineComponent, ref, watch } from '@vue/composition-api'

const RemarkDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    remark: {
      required: true
    }
  },
  setup(props, { emit }) {
    const changedRemark = ref()
    const updateRemark = () => {
      emit('on-ok', changedRemark.value)
    }
    watch(
      () => props.value,
      (value) => {
        if (value) changedRemark.value = props.remark
        else changedRemark.value = ''
      }
    )

    return {
      changedRemark,
      updateRemark
    }
  }
})

export default RemarkDialog
