















































































































































import { defineComponent, ref, onMounted, reactive, provide } from '@vue/composition-api'
import {
  StepButton,
  AddButton,
  ChooseNumber,
  LoaderComponent,
  DateDialog,
  BottomSheetContainer
} from 'components'
import { framebus } from 'plugins'
import {
  endpoints,
  frameBusEvent,
  toCamelCase,
  // convertToWidthCharacter,
  getOneApi,
  replaceUrl,
  getNextAuctionDate,
  urlPath
} from 'utils'
import moment from 'moment'
// import { Customer as CustomerRaw, Item, Variety } from 'typings'
import ImportCSVDialog from './ImportCSVDialog.vue'
import FixImport from './FixImport.vue'

const Sales = defineComponent({
  components: {
    StepButton,
    AddButton,
    ImportCSVDialog,
    ChooseNumber,
    LoaderComponent,
    FixImport,
    DateDialog,
    BottomSheetContainer
  },
  setup(props, { root }) {
    const { $store, $route, $router } = root
    const date = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.date || moment(new Date()).format('YYYY-MM-DD')
    )
    provide('getDate', root)
    const isAddMoreSaleDialog = ref(false)
    const customers = ref<any[]>([])
    const searchedCustomer = ref<any[]>([])
    const selectItems = ref([
      { name: root.$t('common.all') as string, key: 0 },
      { name: root.$t('common.finished') as string, key: 1 },
      { name: root.$t('common.unfinished') as string, key: 2 }
    ])
    const selectedItem = ref(0)
    const searchInfoValue = ref('')
    const bottomSheetSale = reactive({
      show: false,
      buttons: [
        {
          text: root.$t('sales.import_from_csv'),
          event: () => {
            bottomSheetSale.show = true
          }
        },
        {
          text: root.$t('common.cancel'),
          event: () => {
            bottomSheetSale.show = false
          }
        }
      ]
    })
    const init = async () => {
      const data = await getOneApi(`${endpoints.SALE}customer?auction_date=${date.value}`)
      customers.value = data ? toCamelCase(data) : []
      searchedCustomer.value = customers.value
    }

    const goToDate = async (newDate: string) => {
      date.value = newDate
      replaceUrl({ date: date.value, called: 'true' })

      const latestSelectedDates = {
        auctionDate: date.value.toString(),
        packingDateRange: $store.state.common.latestSelectedDates.packingDateRange
          ? $store.state.common.latestSelectedDates.packingDateRange
          : [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
      await init()
    }

    const goToSaleDetail = (salId: any) => {
      $router.push({
        name: urlPath.SALE_DETAIL.name,
        params: { salId },
        query: {
          called: 'true',
          auction_date: date.value
        }
      })
    }
    const onInputSearch = () => {
      searchedCustomer.value = customers.value.filter((item) =>
        item.searchStr.includes(searchInfoValue.value)
      )
    }
    const searchByStatus = () => {
      switch (selectedItem.value) {
        // All
        case 0:
          searchedCustomer.value = customers.value
          break
        case 1:
          // filter all assign with sales result completed
          searchedCustomer.value = customers.value.filter((customer: any) => customer.isConfirmed)
          break
        case 2:
          searchedCustomer.value = customers.value.filter((customer: any) => !customer.isConfirmed)
          break
        default:
          break
      }
    }

    onMounted(async () => {
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        )
      )
        date.value = await getNextAuctionDate()
      await init()
    })

    return {
      date,
      isAddMoreSaleDialog,
      goToDate,
      init,
      selectItems,
      selectedItem,
      searchedCustomer,
      goToSaleDetail,
      searchInfoValue,
      onInputSearch,
      searchByStatus,
      bottomSheetSale
    }
  }
})

export default Sales
