










































































import {
  computed,
  defineComponent,
  ref,
  onMounted,
  inject,
  watch,
  toRefs
} from '@vue/composition-api'
import { snakeCase } from 'lodash'
import { endpoints, scrollBottom, putData, toCamelCase } from 'utils'
import { CommonProperty, Item, NurseryCompany } from 'typings'
import { ValidationObserver } from 'vee-validate'
import {
  SelectInputRow,
  ChooseProperty,
  DialogContainer,
  ChooseNumber,
  CreateCommonForm
} from 'components'
import { detailFee, detailFeeWithTax, toFeeBody } from './index'

const AddFeeDialog = defineComponent({
  components: {
    CreateCommonForm,
    ChooseNumber,
    DialogContainer,
    ChooseProperty,
    SelectInputRow
  },
  props: {
    feeDetail: {
      type: Object,
      required: true
    },
    isTaxExcluded: {
      type: Boolean,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { root, emit, refs }) {
    const rootPage: any = inject('getPage')
    const { $route } = root
    const { salId } = $route.params
    const currentProperty = ref('')
    const dialog = ref(false)
    const numberDialog = ref(false)
    const choosePropertyItems = ref<any[]>([])
    const rows = ref()
    const requiredRows = ref()
    const disabledRows = ref()
    const listStates = ref(['arItem', 'box', 'arUnit', 'price', 'feeAmount'])
    const LIST_PROPERTY = ['arItem', 'box', 'arUnit', 'price', 'feeAmount']
    const fee = ref<any>({})
    const thisPage = ref('sale_fee')
    const nurseryCompanies = ref<Array<NurseryCompany>>([])
    const masterLoading = ref(false)
    const arItems = ref([])
    const arUnits = ref([])
    const taxRate = ref([])
    const { show } = toRefs(props)

    const chooseProperty = (property: string, action: string | null = null) => {
      let curProperty = property
      if (property === 'taxRate' && action === 'next') curProperty = 'feeAmount'
      if (property === 'taxRate' && action === 'prev') curProperty = 'price'
      currentProperty.value = curProperty
      dialog.value = false
      numberDialog.value = false
      // eslint-disable-next-line default-case
      switch (curProperty) {
        case 'arItem':
          dialog.value = true
          choosePropertyItems.value = arItems.value
          break
        case 'arUnit':
          dialog.value = true
          choosePropertyItems.value = arUnits.value
          break
        case 'box':
          numberDialog.value = true
          break
        case 'price':
          numberDialog.value = true
          break
        case 'feeAmount':
          numberDialog.value = true
          break
      }
    }

    const prevProperty = computed(() => {
      const key = currentProperty.value
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = LIST_PROPERTY.length - 1; i >= 0; i -= 1) {
          if (i < indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) - 1
      return listStates.value[indexKey]
    })

    const nextProperty = computed(() => {
      const key = currentProperty.value
      if (key === listStates.value[listStates.value.length - 1]) {
        scrollBottom()
      }
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = 0; i < LIST_PROPERTY.length; i += 1) {
          if (i > indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) + 1
      return listStates.value[indexKey]
    })

    const selectProperty = (action: string, key: string): void => {
      fee.value.arItem = fee.value.arItem ? fee.value.arItem : null
      fee.value.box = fee.value.box === 0 ? null : fee.value.box
      fee.value.arUnit = fee.value.arUnit ? fee.value.arUnit : null
      fee.value.price = fee.value.price === 0 ? null : fee.value.price
      if (key === 'price' || key === 'box')
        fee.value.feeAmount = fee.value.price !== 0 ? fee.value.price * fee.value.box : 0
      if (key === 'amount')
        fee.value.feeAmount = fee.value.feeAmount === 0 ? null : fee.value.feeAmount
      if (action === 'next') chooseProperty(nextProperty.value as string, 'next')
      else if (action === 'prev') chooseProperty(prevProperty.value as string, 'prev')
    }

    const validateFee = async (): Promise<boolean> => {
      const form = refs.form as InstanceType<typeof ValidationObserver>
      const isValid = await form.validate()
      return isValid
    }

    const saveAddOrEditFee = async () => {
      const isValid = await validateFee()
      if (!isValid) {
        return
      }
      // const dataFee = fee.value
      const body = toFeeBody(fee.value, salId, props.isTaxExcluded)
      const [data, success] = await putData(`${endpoints.SALE}update`, body)
      if (!success) return
      rootPage.sale = toCamelCase(data)
      emit('on-close')
    }

    const getTaxRate = (type: any) => {
      if (typeof type === 'undefined' || type === 'no_tax') return null
      return taxRate.value[type]
    }

    const updateFeeTable = (action: string, item: CommonProperty | Item | number): void => {
      const key = currentProperty.value
      fee.value[key] = item
      if (key === 'arItem') {
        fee.value.arUnit = arUnits.value.find((arUnt: any) => arUnt.name === fee.value.arItem.unit)
        fee.value.taxRate = getTaxRate(fee.value.arItem.taxRate)
        fee.value.price = fee.value.ar_item?.unitPrice || null
      }
      // const oldValue = fee.value[key]
      selectProperty(action, key)
    }
    const numberDialogTitle = computed((): string => {
      if (currentProperty.value === 'feeAmount') {
        return props.isTaxExcluded
          ? (root.$t(`sale.fee_amount`) as string)
          : (root.$t(`sale.fee_amount_ti`) as string)
      }
      return root.$t(`master.${snakeCase(currentProperty.value)}`) as string
    })
    const getDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(currentProperty.value)}.title`) as string
    }

    const getDataMaster = async () => {
      arItems.value = rootPage.arItems
      arUnits.value = rootPage.arUnits
      taxRate.value = rootPage.taxRate
    }

    const init = () => {
      // eslint-disable-next-line no-nested-ternary
      rows.value = props.isTaxExcluded
        ? ['arItem', 'box', 'arUnit', 'price', 'taxRate', 'feeAmount']
        : ['arItem', 'box', 'arUnit', 'price', 'feeAmount']
      disabledRows.value = props.isTaxExcluded ? ['taxRate'] : []
      requiredRows.value = props.isTaxExcluded
        ? ['arItem', 'box', 'arUnit', 'taxRate', 'feeAmount']
        : ['arItem', 'box', 'arUnit', 'feeAmount']
      // eslint-disable-next-line no-nested-ternary
      fee.value = props.isAdd
        ? props.isTaxExcluded
          ? { ...detailFee }
          : { ...detailFeeWithTax }
        : { ...props.feeDetail }
    }

    const onKeyDown = (event: any) => {
      if (event.key === 'Enter') saveAddOrEditFee()
    }

    watch(
      () => props.show,
      () => {
        if (props.show) {
          init()
          window.addEventListener('keydown', onKeyDown)
        } else window.removeEventListener('keydown', onKeyDown)
      }
    )

    onMounted(getDataMaster)

    return {
      currentProperty,
      dialog,
      numberDialog,
      choosePropertyItems,
      chooseProperty,
      numberDialogTitle,
      selectProperty,
      updateFeeTable,
      thisPage,
      getDialogTitle,
      nurseryCompanies,
      masterLoading,
      saveAddOrEditFee,
      fee,
      rows,
      requiredRows,
      disabledRows,
      onKeyDown
    }
  }
})
export default AddFeeDialog
