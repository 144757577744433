













































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { StepButton } from 'components'

interface Record {
  boxes: number
  comment: string | null
  customerSalesImportId: number
  id: number
  price: number | null
  quantity: number
  size: string
  salesStems: number
  salesAmount: number
  productName: string
  status: number
  edited: boolean
}

const ImportCSVDetailDialog = defineComponent({
  components: {
    StepButton
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    customerImported: {
      types: Object || null,
      required: true
    },
    auctionDate: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const check = ref('pdf')
    const date = ref<string>()
    const auctionDateModal = ref(false)
    const activeTab = ref(0)
    const errorRecords = ref<Array<Record>>([])
    const successRecords = ref<Array<Record>>([])
    const countErrorNotFixRecords = ref(0)

    const close = () => {
      return emit('on-close', false)
    }

    const openStartFix = () => {
      emit('on-fix', errorRecords.value)
      close()
    }

    const goToDate = (): any => {
      return 0
    }
    watch(
      () => props.value,
      () => {
        const { customerSalesImportDetail } = props.customerImported as any
        // reset records
        successRecords.value = []
        errorRecords.value = []
        countErrorNotFixRecords.value = 0
        // update records to new value
        customerSalesImportDetail?.forEach((record: Record) => {
          if (record.status === 1) {
            successRecords.value.push(record)
          } else if (record.status === 2) {
            errorRecords.value.push(record)
            if (!record.edited) {
              countErrorNotFixRecords.value += 1
            }
          }
        })
      }
    )
    return {
      check,
      date,
      auctionDateModal,
      activeTab,
      close,
      goToDate,
      successRecords,
      errorRecords,
      openStartFix,
      countErrorNotFixRecords
    }
  }
})

export default ImportCSVDetailDialog
