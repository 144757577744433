

































































































import { defineComponent, inject, reactive, ref, watch } from '@vue/composition-api'
import { ChooseNumber, StepButton, BottomSheetContainer, ConfirmDelete } from 'components'
import { endpoints, putData, toCamelCase } from 'utils'
import AddFeeDialog from './AddFeeDialog.vue'
import { toFeeBody } from './index'

const FeeTable = defineComponent({
  props: {
    fees: {
      type: Array,
      required: true
    },
    isTaxExcluded: {
      type: Boolean,
      required: true
    },
    taxRate: {
      type: Object,
      required: false
    },
    isConfirmMainSale: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StepButton,
    ChooseNumber,
    AddFeeDialog,
    BottomSheetContainer,
    ConfirmDelete
  },
  setup(props, { root }) {
    const rootPage: any = inject('getPage')
    const { $route } = root
    const { salId } = $route.params

    const RATE = ref('rate')
    const RATE2 = ref('rate2')
    const isOpen = ref(false)
    const showConfirmDelete = ref(false)
    const curFee = ref<any>({})
    const bottomSheetFee = reactive({
      show: false,
      buttons: [
        {
          text: root.$t('sale.fee.edit'),
          icon: 'mdi-pencil-outline',
          disable: props.isConfirmMainSale,
          event: () => {
            // eslint-disable-next-line no-use-before-define
            openEditFeeDialog()
          }
        },
        {
          text: root.$t('sale.fee.delete'),
          disable: props.isConfirmMainSale,
          icon: 'mdi-delete-outline',
          event: () => {
            showConfirmDelete.value = true
          }
        },
        {
          text: root.$t('common.cancel'),
          event: () => {
            bottomSheetFee.show = false
          }
        }
      ]
    })
    const addFeeArgument = reactive({
      isAdd: false,
      show: false,
      feeDetail: {},
      isTaxExcluded: false
    })

    const onDelete = async () => {
      const body = toFeeBody(curFee.value, salId, props.isTaxExcluded, curFee.value.id)
      const [data, success] = await putData(`${endpoints.SALE}update`, body)
      if (!success) return
      showConfirmDelete.value = false
      rootPage.sale = toCamelCase(data)
    }

    const confirmDelete = async (action: string): Promise<void> => {
      if (action === 'delete') await onDelete()
      showConfirmDelete.value = false
      bottomSheetFee.show = false
    }
    const changeToString = (value: any, unit: any) => {
      return String(value) + String(unit)
    }

    const openEditFeeDialog = () => {
      // emit('edit-fee', curFee.value)
      addFeeArgument.feeDetail = { ...curFee.value }
      addFeeArgument.show = !props.isConfirmMainSale
      addFeeArgument.isAdd = false
      addFeeArgument.isTaxExcluded = props.isTaxExcluded
      bottomSheetFee.show = false
    }

    const calTotalAmount = (type: any) => {
      let result = 0
      props.fees.forEach((item: any) => {
        if (item.taxRateType === type) {
          result += Number(item.amount ?? 0)
        } else if (!type) {
          result += Number(item.amount ?? 0)
        }
      })
      return result
    }
    watch(
      () => props.isConfirmMainSale,
      () => {
        // eslint-disable-next-line no-return-assign,no-param-reassign
        bottomSheetFee.buttons.forEach((item: any) => (item.disable = props.isConfirmMainSale))
        // cancel
        bottomSheetFee.buttons[bottomSheetFee.buttons.length - 1].disable = false
      }
    )

    return {
      changeToString,
      calTotalAmount,
      RATE,
      RATE2,
      isOpen,
      bottomSheetFee,
      showConfirmDelete,
      confirmDelete,
      addFeeArgument,
      curFee
    }
  }
})

export default FeeTable
