






















































import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
  provide
} from '@vue/composition-api'
import moment from 'moment'
import { api } from 'plugins'
import { endpoints, getData, toCamelCase, toSnakeCase } from 'utils'
import { chain, sumBy } from 'lodash'
import {
  DateRange,
  AddButton,
  MasterItemComponent,
  BottomSheetContainer,
  ConfirmDialog
} from 'components'
import { searchResultsHeaders, initTotalSearched } from './index'
import searchConditionPanel from './searchConditionPanel.vue'
import searchResultsPanel from './searchResultsPanel.vue'
import ArDetailDialog from './ArDetailDialog.vue'
import arTable from './ArTable.vue'
import MasterBottomSheet from '../../components/MasterBottomSheet/index.vue'

const accountsReceivable = defineComponent({
  components: {
    searchConditionPanel,
    ArDetailDialog,
    DateRange,
    searchResultsPanel,
    arTable,
    AddButton,
    MasterBottomSheet,
    MasterItemComponent,
    BottomSheetContainer,
    ConfirmDialog
  },
  setup(props, { root }) {
    const instance = getCurrentInstance()?.proxy
    provide('getPage', instance)
    const { $toast } = root
    const headers = ref(searchResultsHeaders)
    const totalSearched = ref<any>({ ...initTotalSearched })
    const loading = ref(false)
    const arsList = ref<Array<any>>([])
    const searchCondition = ref<any>({})
    const searchedArs = ref<Array<any>>([])
    const selectedArs = ref<Array<any>>([])
    const searchedArsGroupByCustomer = ref<Array<any>>([])
    const isSelectedAll = ref(false)
    const showBottomSheetSingle = ref(false)
    const isAddMoreSaleDialog = ref(false)
    const isShowConFirmCreateBill = ref(false)
    const detailAr = ref({
      arItem: null,
      unit: null,
      price: null,
      quantity: null,
      amount: null
    })
    const arItems = ref([])
    const arUnits = ref([])

    const customers = ref<Array<any>>([])

    const getListSelectedArs = () => {
      // eslint-disable-next-line no-shadow
      const selectedArs: any[] = []
      searchedArsGroupByCustomer.value.forEach((cus) => {
        // eslint-disable-next-line camelcase
        cus.ars.forEach((ar: { isSelected: any; id: any }) => {
          if (ar.isSelected) selectedArs.push(ar.id)
        })
      })
      return selectedArs
    }
    const createBill = async () => {
      const arIds = getListSelectedArs()
      if (arIds.length === 0) {
        $toast.error(root.$t('accounts_receivable.selected_ars_empty'))
        return
      }
      loading.value = true
      try {
        const param = {
          period_from: searchCondition.value.auctionDateFrom,
          period_to: searchCondition.value.auctionDateTo,
          ar_ids: arIds
        }
        await api.post(`${endpoints.BILL}`, param)
        // eslint-disable-next-line no-use-before-define
        await getDataAr()
        $toast.success(root.$t('accounts_receivable.create_bill_success'))
      } catch (e) {
        $toast.error(root.$t('common.msg.system_failure'))
      } finally {
        loading.value = false
      }
    }
    const updateCloseStatus = async (isClosed: boolean, isCreateBill = false) => {
      const arIds = getListSelectedArs()
      if (arIds.length === 0) {
        $toast.error(root.$t('accounts_receivable.selected_ars_empty'))
        return
      }
      if (isClosed) loading.value = true
      try {
        const param = {
          is_closed: isClosed,
          ars: arIds
        }
        await api.post(`${endpoints.AR}close`, param)
        if (isCreateBill) await createBill()
        else {
          // eslint-disable-next-line no-use-before-define
          await getDataAr()
          if (isClosed) {
            $toast.success(root.$t('accounts_receivable.close_ar_success'))
          } else {
            $toast.success(root.$t('accounts_receivable.unclose_ar_success'))
          }
        }
      } catch (e) {
        loading.value = false
        $toast.error(root.$t('common.msg.system_failure'))
      } finally {
        isShowConFirmCreateBill.value = false
      }
    }

    const closeArs = async (isClosed: boolean) => {
      const arIds = getListSelectedArs()
      if (arIds.length === 0) {
        $toast.error(root.$t('accounts_receivable.selected_ars_empty'))
        return
      }
      if (isClosed) {
        isShowConFirmCreateBill.value = true
      } else {
        await updateCloseStatus(false)
      }
    }

    const bottomSheetAr = reactive({
      show: false,
      buttons: [
        {
          text: root.$t('accounts_receivable.close_ars'),
          icon: 'mdi-lock-outline',
          event: () => {
            closeArs(true)
            bottomSheetAr.show = false
          }
        },
        {
          text: root.$t('accounts_receivable.unclose_ars'),
          icon: 'mdi-lock-open-variant-outline',
          event: () => {
            closeArs(false)
            bottomSheetAr.show = false
          }
        },
        {
          text: root.$t('accounts_receivable.create_bill'),
          icon: 'mdi-file-document-outline',
          event: () => {
            createBill()
            bottomSheetAr.show = false
          }
        },
        {
          text: root.$t('common.cancel'),
          event: () => {
            bottomSheetAr.show = false
          }
        }
      ]
    })

    const toggleArCheckBox = (arId: any, value: any) => {
      searchedArsGroupByCustomer.value.forEach((group) => {
        // eslint-disable-next-line camelcase
        group.ars.forEach((ar: { id: any; isSelected: any }) => {
          if (ar.id === arId) {
            // eslint-disable-next-line no-param-reassign
            ar.isSelected = value
            if (value) {
              if (!selectedArs.value.includes(ar.id))
                selectedArs.value = [...selectedArs.value, ar.id]
              // eslint-disable-next-line no-shadow
            } else selectedArs.value = selectedArs.value.filter((arId) => arId !== ar.id)
          }
        })
        // eslint-disable-next-line no-param-reassign,camelcase
        group.isSelected = !group.ars.find((ar: any) => ar.isSelected === false)
      })
      isSelectedAll.value = !searchedArsGroupByCustomer.value.find(
        (cus) => cus.isSelected === false
      )
      // eslint-disable-next-line no-use-before-define
      calTotalResult()
    }

    const toggleAll = () => {
      isSelectedAll.value = !isSelectedAll.value
      searchedArsGroupByCustomer.value.forEach((group) => {
        // eslint-disable-next-line camelcase
        group.ars.forEach((ar: { active: boolean; isSelected: boolean; id: any }) => {
          if (!ar.active) {
            return
          }
          // eslint-disable-next-line no-param-reassign
          ar.isSelected = isSelectedAll.value
          if (isSelectedAll.value) {
            if (!selectedArs.value.includes(ar.id))
              selectedArs.value = [...selectedArs.value, ar.id]
          } else selectedArs.value = selectedArs.value.filter((arId) => arId !== ar.id)
        })
        // eslint-disable-next-line no-param-reassign
        group.isSelected = isSelectedAll.value
      })
      // eslint-disable-next-line no-use-before-define
      calTotalResult()
    }
    const toggleGroupCheckBox = (customer: any, value: any) => {
      searchedArsGroupByCustomer.value.forEach((group) => {
        if (group.customerName === customer) {
          // eslint-disable-next-line no-param-reassign
          group.isSelected = value
          // eslint-disable-next-line camelcase
          group.ars.forEach((ar: { active: boolean; isSelected: any; id: any }) => {
            if (!ar.active) {
              return
            }
            // eslint-disable-next-line no-param-reassign
            ar.isSelected = value
            if (value) {
              if (!selectedArs.value.includes(ar.id))
                selectedArs.value = [...selectedArs.value, ar.id]
            } else selectedArs.value = selectedArs.value.filter((arId) => arId !== ar.id)
          })
        }
      })
      isSelectedAll.value = !searchedArsGroupByCustomer.value.find(
        (cus) => cus.isSelected === false
      )
      // eslint-disable-next-line no-use-before-define
      calTotalResult()
    }
    const groupByCustomer = (data: any) => {
      return chain(data)
        .groupBy('customerName')
        .map((value: any, key) => ({
          isSelected: !value.find((arDetail: any) => arDetail.isSelected === false),
          customerName: key,
          totalAmount: sumBy(value, 'totalAmount'),
          totalClosedAmount: sumBy(
            value.filter((arDetail: any) => arDetail.isClosed),
            'totalAmount'
          ),
          auctionDateToClosed: moment(
            new Date(
              Math.max.apply(
                null,
                value.filter((ar: any) => ar.isClosed).map((ar: any) => new Date(ar.auctionDate))
              )
            )
          ).format('YYYY-MM-DD'),
          auctionDateTo: moment(
            new Date(
              Math.max.apply(
                null,
                value.map((ar: any) => new Date(ar.auctionDate))
              )
            )
          ).format('YYYY-MM-DD'),
          isClosedAuctionDateTo:
            value.find(
              (e: any) =>
                e.auctionDate ===
                moment(
                  new Date(
                    Math.min.apply(
                      null,
                      value.map((ar: any) => new Date(ar.auctionDate))
                    )
                  )
                ).format('YYYY-MM-DD')
            )?.isClosed || false,
          isBilledAuctionDateTo:
            value.find(
              (e: any) =>
                e.auctionDate ===
                moment(
                  new Date(
                    Math.min.apply(
                      null,
                      value.map((ar: any) => new Date(ar.auctionDate))
                    )
                  )
                ).format('YYYY-MM-DD')
            )?.isBilled || false,
          ars: value
        }))
        .value()
    }
    const convertData = (data: any[]) => {
      let filter
      if (searchCondition.value.option) {
        filter = data
      } else {
        filter = data?.filter((ar) => ar.active)
      }
      return filter.map((ar) => ({
        ...ar,
        isSelected: selectedArs.value.includes(ar.id),
        customerName: ar.cus.nameShort ?? ar.cus.name
      }))
    }
    const getBody = () => {
      const body = { ...searchCondition.value }
      Object.keys(body).forEach((key) => {
        if (body[key as keyof typeof body] === null) {
          delete body[key as keyof typeof body]
        }
      })
      return toSnakeCase(body)
    }

    const getArItems = async () => {
      const data = await getData(['arItem', 'arUnit'])
      arItems.value = data.arItems ? toCamelCase(data.arItems) : []
      arUnits.value = data.arUnits ? toCamelCase(data.arUnits) : []
    }
    const getCustomers = async () => {
      const { data } = await api.get(`${endpoints.CUSTOMERS}`)
      // eslint-disable-next-line camelcase
      customers.value = data.map((cus: { nameShort: any; name: any }) => ({
        ...cus,
        nameShort: cus.nameShort ?? cus.name
      }))
    }
    const updateTotalSearchedFromArs = (ars: any[]) => {
      ars.forEach((ar) => {
        // if selectedArs is empty, total is all ars; if selectedArs isn't empty, total is all selectedArs
        if (!selectedArs.value.length || ar.isSelected) {
          totalSearched.value.totalArNumber += 1
          // eslint-disable-next-line operator-assignment
          totalSearched.value.totalArDetails =
            totalSearched.value.totalArDetails + ar.arDetails.length
          totalSearched.value.totalAmount += ar.totalAmount
          if (ar.isClosed) {
            totalSearched.value.totalAmountClosedAr += ar.totalAmount
            if (totalSearched.value.alreadyClosedArDateFrom) {
              totalSearched.value.alreadyClosedArDateFrom =
                new Date(totalSearched.value.alreadyClosedArDateFrom) < new Date(ar.auctionDate)
                  ? totalSearched.value.alreadyClosedArDateFrom
                  : moment(new Date(ar.auctionDate)).format('YYYY-MM-DD')
            } else {
              totalSearched.value.alreadyClosedArDateFrom = moment(new Date(ar.auctionDate)).format(
                'YYYY-MM-DD'
              )
            }
            if (totalSearched.value.alreadyClosedArDateTo) {
              totalSearched.value.alreadyClosedArDateTo =
                new Date(totalSearched.value.alreadyClosedArDateTo) > new Date(ar.auction_date)
                  ? totalSearched.value.alreadyClosedArDateTo
                  : moment(new Date(ar.auctionDate)).format('YYYY-MM-DD')
            } else {
              totalSearched.value.alreadyClosedArDateTo = moment(new Date(ar.auctionDate)).format(
                'YYYY-MM-DD'
              )
            }
          }
          if (totalSearched.value.dateFrom) {
            totalSearched.value.dateFrom =
              new Date(totalSearched.value.dateFrom as string) < new Date(ar.auctionDate)
                ? totalSearched.value.dateFrom
                : moment(new Date(ar.auctionDate)).format('YYYY-MM-DD')
          } else {
            totalSearched.value.dateFrom = moment(new Date(ar.auctionDate)).format('YYYY-MM-DD')
          }
          if (totalSearched.value.dateTo) {
            totalSearched.value.dateTo =
              new Date(totalSearched.value.dateTo) > new Date(ar.auctionDate)
                ? totalSearched.value.dateTo
                : moment(new Date(ar.auctionDate)).format('YYYY-MM-DD')
          } else {
            totalSearched.value.dateTo = moment(new Date(ar.auctionDate)).format('YYYY-MM-DD')
          }
          totalSearched.value.date =
            totalSearched.value.dateTo && totalSearched.value.dateFrom
              ? `${moment(totalSearched.value.dateFrom).format('MM月DD日(ddd)')} ~ ${moment(
                  totalSearched.value.dateTo
                ).format('MM月DD日(ddd)')}`
              : ''
        }
      })
    }
    const calTotalResult = () => {
      totalSearched.value = { ...initTotalSearched }
      searchedArsGroupByCustomer.value.forEach((cus) => {
        updateTotalSearchedFromArs(cus.ars)
      })
    }
    const updateSearchedArs = () => {
      searchedArs.value = [...arsList.value]
      if (searchCondition.value.arItem) {
        // Get only the arDetail has dateFrom = searchCondition.value.arItemId and update totalAmount of ar
        searchedArs.value = searchedArs.value.map((ar) => {
          const searchedArDetail = ar.arDetails.filter(
            // eslint-disable-next-line camelcase
            (arDetail: { arItem: { id: any } }) =>
              arDetail.arItem.id === searchCondition.value.arItem
          )
          return {
            ...ar,
            arDetails: searchedArDetail,
            totalAmount: sumBy(
              // eslint-disable-next-line camelcase
              searchedArDetail.filter((arDetail: { active: any }) => arDetail.active),
              'amount'
            )
          }
        })
      }
      isSelectedAll.value = !searchedArs.value.find((ar) => ar.isSelected === false)
    }

    const getDataAr = async () => {
      loading.value = true
      const data = (await getData(['ar'], { ar: getBody() })).ars
      arsList.value = data ? convertData(toCamelCase(data)) : []
      await updateSearchedArs()
      searchedArsGroupByCustomer.value = groupByCustomer(searchedArs.value)
      loading.value = false
      await calTotalResult()
    }
    const item = ref([])
    const updateSearchCondition = async (info: any) => {
      selectedArs.value = []
      searchCondition.value = { ...info }
      await getDataAr()
    }
    const isShowArDetailDialog = ref(false)

    const closeDialog = () => {
      isShowArDetailDialog.value = false
    }
    const openDialog = (data: any) => {
      isShowArDetailDialog.value = true
      item.value = data.ar.arDetails.map((obj: any) => ({
        arItem: obj.arItem.name,
        quantity: Number(obj.quantity),
        price: Number(obj.price),
        amount: Number(obj.amount)
      }))
    }

    const reload = async () => {
      await getArItems()
      await getCustomers()
      await updateSearchCondition(searchCondition.value)
    }

    onMounted(async () => {
      await getCustomers()
      await getArItems()
    })
    return {
      loading,
      headers,
      totalSearched,
      getDataAr,
      customers,
      searchedArs,
      isShowArDetailDialog,
      closeDialog,
      item,
      isSelectedAll,
      toggleArCheckBox,
      openDialog,
      showBottomSheetSingle,
      isAddMoreSaleDialog,
      closeArs,
      createBill,
      updateCloseStatus,
      toggleGroupCheckBox,
      toggleAll,
      arItems,
      arUnits,
      detailAr,
      updateSearchCondition,
      searchedArsGroupByCustomer,
      bottomSheetAr,
      isShowConFirmCreateBill,
      reload
    }
  }
})
export default accountsReceivable
