



































































import {
  computed,
  defineComponent,
  inject,
  ref,
  toRefs,
  watch,
  getCurrentInstance
} from '@vue/composition-api'
import { CreateCommonForm, SelectInputRow, ChooseProperty, ChooseNumber } from 'components'
import { checkIsOnPC, scrollBottom, toSnakeCase, endpoints, patchData } from 'utils'
import { snakeCase } from 'lodash'
import { ValidationObserver } from 'vee-validate'
import { CommonProperty, Item } from 'typings'

const ArDetailDialog = defineComponent({
  components: {
    CreateCommonForm,
    SelectInputRow,
    ChooseProperty,
    ChooseNumber
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    arItems: {
      type: Array,
      required: false,
      default: () => []
    },
    arUnits: {
      type: Array,
      required: false,
      default: () => []
    },
    item: {
      type: Object || null,
      required: true
    },
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root, emit, refs }) {
    const rootPage: any = inject('getPage')
    const { arItems, arUnits } = toRefs(props)
    const currentProperty = ref('')
    const isOnPC = checkIsOnPC()
    const dialog = ref(false)
    const numberDialog = ref(false)
    const arItemDialog = ref(false)
    const rows = ref(['arItem', 'quantity', 'arUnit', 'price', 'amount', 'remark'])
    const choosePropertyItems = ref<any[]>([])
    const disabledRows = ref([])
    const requiredRows = ref(['arItem', 'quantity', 'arUnit', 'amount'])
    const listStates = ref(['arItem', 'quantity', 'arUnit', 'price', 'amount'])
    const LIST_PROPERTY = ['arItem', 'quantity', 'arUnit', 'price', 'amount']
    const masterLoading = ref(false)
    const { show } = toRefs(props)
    const initArDetail = {
      id: null,
      arItem: null,
      arUnit: null,
      quantity: null,
      price: null,
      taxAmount: null,
      amount: null,
      remark: null
    }
    const arDetail = ref<any>({})
    const arId = ref(null)

    const chooseProperty = (property: any) => {
      currentProperty.value = property
      dialog.value = false
      numberDialog.value = false
      arItemDialog.value = false
      switch (currentProperty.value) {
        case 'arItem':
          dialog.value = true
          choosePropertyItems.value = [...arItems.value]
          break
        case 'arUnit':
          dialog.value = true
          choosePropertyItems.value = [...arUnits.value]
          break
        case 'quantity':
          numberDialog.value = true
          break
        case 'price':
          numberDialog.value = true
          break
        case 'amount':
          numberDialog.value = true
          break
        default:
          break
      }
    }

    const nextProperty = computed(() => {
      const key = currentProperty.value
      if (key === listStates.value[listStates.value.length - 1]) {
        scrollBottom()
      }
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = 0; i < LIST_PROPERTY.length; i += 1) {
          if (i > indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) + 1
      return listStates.value[indexKey]
    })
    const prevProperty = computed(() => {
      const key = currentProperty.value
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = LIST_PROPERTY.length - 1; i >= 0; i -= 1) {
          if (i < indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) - 1
      return listStates.value[indexKey]
    })
    const selectProperty = (action: string): void => {
      arDetail.value.arItem = arDetail.value.arItem ? arDetail.value.arItem : null
      arDetail.value.arUnit = arDetail.value.arUnit ? arDetail.value.arUnit : null
      arDetail.value.price = arDetail.value.price === 0 ? null : arDetail.value.price
      arDetail.value.quantity = arDetail.value.quantity === 0 ? null : arDetail.value.quantity
      arDetail.value.amount = arDetail.value.amount === 0 ? null : arDetail.value.amount
      if (action === 'next') chooseProperty(nextProperty.value as string)
      else if (action === 'prev') chooseProperty(prevProperty.value as string)
    }
    const updateArTable = (action: string, item: CommonProperty | Item | number): void => {
      const key = currentProperty.value
      arDetail.value[key] = item
      if (key === 'arItem') {
        arDetail.value.arUnit = arUnits.value.find(
          (unt: any) => unt.name === arDetail.value.arItem.unit
        )
        arDetail.value.price = arDetail.value.ar_item?.unitPrice || null
      }
      // console.log(arDetail.value)
      // const oldValue = detailFee.value[key]
      selectProperty(action)
    }
    const validateFee = async (): Promise<boolean> => {
      const form = refs.form as InstanceType<typeof ValidationObserver>
      const isValid = await form.validate()
      return isValid
    }
    const toBody = () => {
      const arDetailBody = {
        id: arDetail.value.id,
        ar_item_id: arDetail.value.arItem.id,
        ar_unit_id: arDetail.value.arUnit.id,
        amount: arDetail.value.amount,
        quantity: arDetail.value.quantity,
        price: arDetail.value.price,
        tax_amount: arDetail.value.taxAmount,
        remark: arDetail.value.remark
      }
      return {
        details: [{ ...arDetailBody }],
        deleted_details: [],
        is_update_remark: false,
        remark: null
      }
    }
    const saveAddOrEditArDetail = async () => {
      const isValid = await validateFee()
      if (!isValid) return
      const body = toBody()
      const [data, success] = await patchData(`${endpoints.AR}${arId.value}`, body)
      if (!success) return
      emit('on-close')
      await rootPage.getDataAr()
    }

    const getNumberDialogTitle = (): string => {
      return root.$t(`master.${toSnakeCase(currentProperty.value)}`) as string
    }
    const getDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(currentProperty.value)}.title`) as string
    }

    const init = () => {
      arDetail.value = props.isAdd ? { ...initArDetail } : { ...props.item.arDetail }
      arId.value = props.item.ar.id
    }

    const onKeyDown = (event: any) => {
      if (event.key === 'Enter') saveAddOrEditArDetail()
    }

    watch(
      () => props.show,
      () => {
        if (props.show) {
          init()
          window.addEventListener('keydown', onKeyDown)
        } else window.removeEventListener('keydown', onKeyDown)
      }
    )

    return {
      isOnPC,
      chooseProperty,
      arItemDialog,
      dialog,
      getDialogTitle,
      currentProperty,
      choosePropertyItems,
      rows,
      disabledRows,
      requiredRows,
      arDetail,
      numberDialog,
      getNumberDialogTitle,
      updateArTable,
      masterLoading,
      selectProperty,
      saveAddOrEditArDetail,
      onKeyDown
    }
  }
})
export default ArDetailDialog
