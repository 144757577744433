

























import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api, framebus } from 'plugins'
import { endpoints, toCamelCase, showError, frameBusEvent } from 'utils'
import { Setting, BottomSheetButton } from 'typings'
import { SettingDialog, MasterItemComponent } from 'components'

interface SetUp {
  settings: Ref<Setting[]>
  showSettingDialog: Ref<boolean>
  settingProp: Ref<unknown>
  openSettingDialog: (setting: Setting) => void
  getSettings: () => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  bottomSheetListButton: Array<BottomSheetButton>
}

const Settings = defineComponent({
  components: {
    SettingDialog,
    MasterItemComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const settings: Ref<Setting[]> = ref([])
    const isSorting = ref(false)
    const settingProp = ref({})
    const showSettingDialog = ref(false)
    const { $toast, $store } = root

    const loading = ref(false)

    const bottomSheetListButton: Array<BottomSheetButton> = []

    const getSettings = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(endpoints.SETTINGS)
        // need to convert result from snake_case to camelCase
        const result = toCamelCase(data)
        $store.commit('setSettingMaster', data)
        framebus.emit(frameBusEvent.SETTING_MASTER, data)
        settings.value = result.map((setting: any) => {
          const i18nKey = `master.setting.keys.${setting.key}`
          return {
            id: setting.id,
            value: setting.value,
            key: setting.key,
            name: root.$t(i18nKey),
            searchStr: `${setting.id}|${root.$t(i18nKey)}|${setting.key}`
          }
        })
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const openSettingDialog = (setting: Setting): void => {
      if (setting) {
        settingProp.value = JSON.parse(JSON.stringify(setting))
      } else {
        settingProp.value = {}
      }
      showSettingDialog.value = true
    }

    onMounted(getSettings)

    return {
      settings,
      settingProp,
      showSettingDialog,
      isSorting,
      loading,
      openSettingDialog,
      getSettings,
      bottomSheetListButton
    }
  }
})

export default Settings
