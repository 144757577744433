




















































































































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { getData, replaceUrl } from 'utils'
import moment from 'moment'
import { DateRange } from 'components'
import { ArClosingStatusList, BillingClosingStatusList } from './index'

const searchConditionPanel = defineComponent({
  components: { DateRange },
  props: {
    arItems: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const { $route } = root
    const customers = ref()
    const closingGroups = ref()
    const initUrl: any = $route.query
    const defaultAuctionDateFrom = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD')
    const defaultAuctionDateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    const searchInfo = ref({
      closingGroup: initUrl.closingGroup ? Number(initUrl.closingGroup) : null,
      customer: initUrl.customer ? Number(initUrl.customer) : null,
      id: initUrl.id ? Number(initUrl.id) : null,
      bill: initUrl.bill ? Number(initUrl.bill) : null,
      auctionDateFrom: initUrl?.auctionDateFrom
        ? moment(initUrl.auctionDateFrom).format('YYYY-MM-DD')
        : defaultAuctionDateFrom,
      auctionDateTo: initUrl?.auctionDateTo
        ? moment(initUrl.auctionDateTo).format('YYYY-MM-DD')
        : defaultAuctionDateTo,
      arItem: initUrl.arItem ? Number(initUrl.arItem) : null,
      isClosed: initUrl.isClosed ? Number(initUrl.isClosed) : null,
      isBilled: initUrl.isBilled ? Number(initUrl.isBilled) : null,
      option: initUrl.option === 'true'
    })
    const dateTo = ref(defaultAuctionDateTo)
    const dateFrom = ref(defaultAuctionDateFrom)

    // eslint-disable-next-line camelcase
    const searchItems = (item: { search_str: string | any[] }, queryText: any) => {
      return item.search_str.includes(queryText)
    }
    const search = () => {
      replaceUrl({
        closingGroup: searchInfo.value.closingGroup?.toString(),
        customer: searchInfo.value.customer?.toString(),
        id: searchInfo.value.id?.toString(),
        bill: searchInfo.value.bill?.toString(),
        auctionDateFrom: searchInfo.value.auctionDateFrom,
        auctionDateTo: searchInfo.value.auctionDateTo,
        arItem: searchInfo.value.arItem?.toString(),
        isClosed: searchInfo.value.isClosed?.toString(),
        isBilled: searchInfo.value.isBilled?.toString(),
        option: searchInfo.value.option ? 'true' : 'false'
      })
      emit('on-search', searchInfo.value)
    }

    const setDate = (start: string, end: string) => {
      searchInfo.value.auctionDateFrom = start
      searchInfo.value.auctionDateTo = end
    }

    const init = async () => {
      const data = await getData(['customer', 'closingGroup'])
      customers.value = data.customers
        ? (data.customers ?? []).map((cus: any) => ({
            ...cus,
            nameShort: cus.shortName ?? cus.name
          }))
        : []
      closingGroups.value = data.closingGroups ? data.closingGroups : []
      search()
    }

    onMounted(init)
    return {
      searchInfo,
      customers,
      closingGroups,
      search,
      searchItems,
      ArClosingStatusList,
      BillingClosingStatusList,
      dateTo,
      dateFrom,
      setDate
    }
  }
})
export default searchConditionPanel
